import axios from 'axios';

export const configureAxios = () => {
  const apiKey = process.env.VUE_APP_API_KEY;

  if (apiKey) {
    axios.defaults.headers.common['Authorization'] = `${apiKey}`;
  } else {
    console.error('API key is not defined');
  }
};